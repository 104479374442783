@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-50 text-gray-800 font-sans;
}

button {
  @apply transition ease-in-out duration-300 transform hover:scale-105;
}

input {
  @apply focus:ring-2 focus:ring-blue-500 focus:outline-none;
}

/* h2 {
  @apply text-white font-semibold;
} */

form {
  @apply space-y-4;
}

.tracker-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 15px;
  width: 300px; /* Adjust the width as needed */
  text-align: center;
}

.tracker-header h5 {
  margin-bottom: 10px;
}

.tracker-input {
  width: 100%;
  margin-bottom: 10px;
}

.tracker-actions {
  margin-bottom: 10px;
}

.output-message {
  margin-top: 10px;
}

.tracker-image {
  width: 100%;
  height: auto;
  margin-top: 10px;
}

.tracker-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.nav-tabs .nav-link {
  border-radius: 0;
}

.nav-animation-enter {
  opacity: 0;
  transform: scale(0.9);
}
.nav-animation-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.nav-animation-exit {
  opacity: 1;
}
.nav-animation-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
/* In your global CSS file */
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
@media (min-width: 769px) {
  .sidebar {
    display: block;
  }
}

body, #root {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
}

/* In your global CSS file */
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
@media (min-width: 769px) {
  .sidebar {
    display: block;
  }
}